import React from 'react'
import { Helmet } from 'react-helmet'
import Header from '../components/header'
import Footer from '../components/footer'

export default function Terms(props) {
	return <>
		<Helmet>
			<title>Terms &amp; Conditions | Kelgrand</title>
		</Helmet>

		<Header path={props.path} />

		<main className='container'>
			<h1>
				Terms &amp; Conditions
			</h1>
			<p>
				Valid from 1.6.2019<br/>
				By using the Kelgrand applications, you agree to the following terms.
			</p>
			<p>
				Prevention of abuse<br/>
				Applications can only be used by those users who have a valid license to use. Transferring user accounts between people is not allowed. In case of violations, we can charge additional license fees. Applications may only be used within the limits of normal use. 8 hours of work is considered normal use. The use of automated data access tools is not permitted.
			</p>
			<p>
				Limitation of liability<br/>
				Our apps are thoroughly tested, but errors can occur. Kelgrand shall not be liable for any damage caused by, but not limited to software errors, incomplete information, lost information or unavailability.
			</p>
			<p>
				Termination<br/>
				Kelgrand reserves the right to suspend or limit the operation of services at any time. We are not responsible for any damage.
			</p>
		</main>

		<Footer />
	</>
}
